import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserRight } from 'app/common/user-right';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EMPTY } from './constants';
import { User } from './dto/user-dto';
import { MenuService } from './services/menu.service';
import { SessionService } from './services/session.service';

const whitelistUrls: Array<RegExp> = [
    new RegExp('/error/.*'),
    new RegExp('/setup-2fa'),
    new RegExp('/unconfirmed-email'),
    new RegExp('/change-password'),
    new RegExp('/weak-password'),
    new RegExp('/verify-2fa')
];

@Injectable()
export class AuthGuard {
    constructor(private readonly session: SessionService, private readonly router: Router, private readonly menu: MenuService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return (this.session.isInitialized ? Promise.resolve() : this.session.initializeUser()).then(
            () => {
                if (whitelistUrls.some((rx) => rx.test(state.url))) {
                    return true;
                }
                if (this.session.user.permissions?.length === 0) {
                    this.router.navigate(['/error/forbidden'], { queryParams: { reason: 'no-permissions' } }).catch(EMPTY);
                    return false;
                } else {
                    return this.checkPermission(this.session.user, state).then((hasPermission) => {
                        if (!hasPermission) {
                            this.router.navigate(['/error/forbidden']).catch(EMPTY);
                        }
                        return hasPermission;
                    });
                }
            },
            () => {
                this.router.navigate(['/login'], { queryParams: { returnUrl: encodeURIComponent(state.url) } }).catch(EMPTY);
                return false;
            }
        );
    }

    private checkPermission(user: User, state: RouterStateSnapshot) {
        return this.menu
            .getMenuItem(state.url)
            .pipe(
                map((item) => {
                    if (!item) {
                        return false;
                    }
                    if (item.permissions && item.permissions.length) {
                        return item.permissions.some((x) => user.hasPermission(x as UserRight));
                    } else {
                        return true;
                    }
                })
            )
            .toPromise();
    }
}
